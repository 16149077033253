<script>
  import {ripple} from 'svelte-ripple-action';
  import { createEventDispatcher } from "svelte";

  export let prio = false;
  export let borderless = false;
  export let logName;
  export let title = null;
  export let href;
  export let type;

  export let delayUntilTrue = true; // If set to false, clicking will not have effect until this variable becomes true
  export let requiresInteraction = false;
  let clickEvent = null;
  export let submitAction;
  let dispatch = createEventDispatcher();

  let logLabel = logName ? 'lp_aid_'+logName : '';
  let button;

  function clickHandler(evt) {
    if (delayUntilTrue) {
      dispatch('click', evt);
      return;
    }
    evt.preventDefault();
    clickEvent = evt;
  }

  // This is triggered by either delayUntilTrue becoming true, or clickEvent changing state.
  $: {
    if (clickEvent != null && delayUntilTrue) {
      // Button has now been clicked, and delayUntilTrue has become true
      performClick(clickEvent);
    }
  }

  function performClick(evt) {
    if (button.form) {
      button.form.submit();
    }
    dispatch('click', evt);
  }
</script>

<div class="{prio ? 'prio' : ''} {borderless ? 'borderless' : ''} {logLabel}">
  {#if href}
    <a bind:this={button} on:click use:ripple {href} {title}>
      <slot/>
    </a>
  {:else}
    <button bind:this={button} on:click={clickHandler} use:ripple {type} {title} name={submitAction ? "submitaction" : undefined} value={submitAction ? submitAction : ""}>
        {#if clickEvent == null}
          <slot/>
        {:else}
          {#if !delayUntilTrue && requiresInteraction}
            Fullfør skjema over.
          {:else}
            Vent litt...
          {/if}
        {/if}
    </button>
  {/if}
</div>

<style>
    @import "../common/colors.css";
    div {
        overflow: hidden;
        position: relative;
        background: var(--white);
        display: block;
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        width: 100%;
        max-width: 360px;
        box-sizing: border-box;
        cursor: pointer;
    }

    @media screen and (max-width: 500px) {
      div {
        max-width: 100%;
      }
    }

    div :focus-within {
        text-decoration: underline;
    }

    div button, div a {
        color: var(--black);
        box-sizing: border-box;
        cursor: pointer;
        border: 3px solid var(--black);
        background: transparent;
        display: inline-block;
        padding: 13px 13px;
        width: 100%;
        position: relative;
        z-index: 2;
        line-height: 1;
        font-weight: 700;
        text-decoration: none;
        height: 100%;
    }

    div button:disabled, div a:disabled {
        filter: opacity(0.2);
        cursor: not-allowed;
    }

    div:global(.prio) {
        background: var(--black);
        box-shadow: inset 0 -2px 2px rgba(0, 0, 0, 0.2);
        border: 0 none;
    }

    div :global(.button) {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    div.borderless {
        background: var(--lightGray);
    }

    div.borderless.prio {
      background: var(--black);
    }

    div.borderless button, div.borderless a {
        border: 0px;
        background: none;
        padding: 7px;
    }

    div :global(.ripple) {
        background: var(--black);
        border-radius: 100%;
        position: absolute;
        z-index: 1;
        transform: scale(0);
        animation: aid-ripple 1000ms;
        opacity: 0.75;
    }

    @keyframes aid-ripple {
        to {
            opacity: 0;
            transform: scale(1);
        }
    }

    div:global(.prio .ripple) {
        background: var(--white);
    }

    div.prio button:hover, div.prio a:hover {
        color: var(--white);
    }

    div button:hover, div a:hover {
        color: var(--black);
    }

    div.prio button, div.prio a {
        color: var(--white);
    }

</style>